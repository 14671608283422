import { isNullOrUndefined } from "table";

export function isEqual(value1: any, value2: any): boolean {
  if (isNullOrUndefined(value1) || isNullOrUndefined(value2)) {
    return value1 === value2;
  }

  if (typeof value1 !== typeof value2) {
    return false;
  }

  const type = typeof value1;

  if (type === "object") {
    return JSON.stringify(value1) === JSON.stringify(value2);
  }

  return value1 === value2;
}
