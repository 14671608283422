
import { defineComponent, reactive } from "vue";
import {
  ApiHelper,
  ButtonComponent,
  ContextMenuOption,
  ContextMenuService,
  dateFormat,
  Dictionary,
  GlobalContext,
  HttpService,
  Logger,
  NotificationsService,
  PanelFilters,
  PanelFiltersSet,
  ScreenSpinner,
  TABLE_ICONS,
  TableActionsComponent,
  TableComponent,
  TableDataSet,
  TableDataSetFactory,
  TableMultiHeaders,
} from "table";
import AnalyticalReportsCreateReport from "@/modules/analytical-reports/components/AnalyticalReportsCreateReport.vue";
import ApiAReportsService from "@/modules/analytical-reports/services/ApiAReportsService/ApiAReportsService";
import { AReportDataDto } from "@/modules/analytical-reports/common/types";
import AnalyticalReportsFilter from "@/modules/analytical-reports/components/AnalyticalReportsFilter.vue";
import { analyticalReportHelpers } from "@/modules/analytical-reports/common/helpers";
import { ApiVocUserDto } from "@/services/AppUsersService/types";
import AppUsersService from "@/services/AppUsersService/AppUsersService";
import { UserHelper } from "@/common/helpers/UserHelper";
import {
  getStatusAReportName,
  STATUS_AREPORT_NAME,
} from "@/modules/analytical-reports/services/ApiAReportsService/common/types/StatusAReport";
import { AReportsExtraInfo } from "@/modules/analytical-reports/services/ApiAReportsService/common/types/AReportsExtraInfo";
import { ApiListWrapper } from "table/dist/services/Api/types/ApiListWrapper";
import { PanelFiltersSaveModule } from "@/classes/PanelFiltersSaveModule";
import { applyPanelFiltersData } from "@/common/helpers/applyPanelFiltersData";
import { TableMultiHeaderLeft } from "table/dist/components/TableComponent/common/components/TableMultiHeaders/common/types/TableMultiHeaderLeft";
import { TableHeaderCell } from "table/dist/components/TableComponent/common/components/TableMultiHeaders/common/types/TableHeaderCell";
import { ActionButtonDto } from "@/services/ApiActions/types";
import ApiActionsService, {
  RefreshType,
} from "@/services/ApiActions/ApiActionsService";
import { wrapNotifyAndAwaited } from "@/common/helpers/wrapNotifyAndAwaited";
import ActionsButtons from "@/components/ActionButton/ActionsButtons.vue";
import { PageGlobalContext } from "table/dist/services/GlobalContext/types";
import { TableRowObj } from "table/dist/types/TableRowObj";

type Table = {
  tableDataSet: TableDataSet;
  factory: TableDataSetFactory;
};

export default defineComponent({
  name: "AnalyticalReportsList",
  components: {
    TableActionsComponent,
    AnalyticalReportsFilter,
    AnalyticalReportsCreateReport,
    TableComponent,
    TableMultiHeaders,
    ButtonComponent,
    ScreenSpinner,
    PanelFilters,
    ActionsButtons,
  },
  props: {
    aReportListUrl: String,
  },
  setup() {
    const table = reactive({});
    return {
      TABLE_ICONS,
      dateFormat,
      window,
      UserHelper,
      getStatusAReportName,
    };
  },
  data() {
    return {
      open: {
        createReportForm: false,
        filter: false,
      },
      list: null as null | ApiListWrapper<AReportDataDto>,
      table: null as null | Table,
      filterData: analyticalReportHelpers.getInitFilterData(),
      filterDataActual: analyticalReportHelpers.getInitFilterData(),
      listFormFiltered: undefined as undefined | AReportDataDto[],
      error: false,
      usersDict: null as Dictionary<ApiVocUserDto> | null,
      aReportsExtraInfo: null as AReportsExtraInfo | null,
      actions: [] as ActionButtonDto[],
    };
  },
  async created() {
    await wrapNotifyAndAwaited([
      async () => {
        this.usersDict = await AppUsersService.getUsersDict();
      },
      async () => {
        this.actions = await ApiActionsService.getActions(`AReports`);
      },
      async () => {
        this.aReportsExtraInfo = (await ApiAReportsService.getExtraInfo()).json;
      },
    ]);
    await this.initList();
    this.initFilter(true);
  },
  updated() {},
  beforeUnmount() {},
  computed: {
    leftColumnsComputed(): TableMultiHeaderLeft[] {
      const result: TableMultiHeaderLeft[] = [];
      result.push({
        key: "checkbox",
        type: "checkbox",
      });
      // result.push({
      //   key: "numRow",
      //   caption: DEFAULT_LEFT_COLUMN_NAME,
      // });

      return result;
    },
    listFormFilteredActual(): AReportDataDto[] | undefined {
      return this.list?.result.filter((listValue: AReportDataDto) => {
        const { areports_type } = this.filterDataActual;
        if (areports_type?.length) {
          if (
            !areports_type.some(
              (areports_type) => listValue.areport_name === areports_type.value,
            )
          ) {
            return false;
          }
        }

        return true;
      });
    },
  },
  methods: {
    getTable() {
      return this.table as null | Table;
    },
    async initList(refresh: RefreshType = "all") {
      try {
        this.list = this.aReportListUrl
          ? (
              await HttpService.get<ApiListWrapper<AReportDataDto>>(
                this.aReportListUrl,
              )
            ).json
          : (await ApiAReportsService.getAReportDataList()).json;
        const uniqId = "analytical-reports";
        const filtersSaveModule = new PanelFiltersSaveModule(uniqId);
        await filtersSaveModule.init();
        const filtersSet = new PanelFiltersSet(filtersSaveModule);
        filtersSet.subject.subscribe((data) =>
          applyPanelFiltersData(this.table!.tableDataSet as TableDataSet, data),
        );
        const factory = new TableDataSetFactory({
          headers: [
            "areport_name",
            "areport_creator",
            "areport_state",
            "areport_ts",
            "context_menu",
          ],
          modelDtoArray: [
            {
              caption: "Название",
              field: "areport_name",
              type: "slot",
              slot: "areport_name",
              width: "500px",
            },
            {
              caption: "Автор",
              field: "areport_creator",
              type: "users",
              width: "200px",
            },
            {
              caption: "Статус",
              field: "areport_state",
              type: "areport_state",
              width: "130px",
            },
            {
              caption: "Дата создания",
              field: "areport_ts",
              type: "date",
              width: "105px",
            },
            {
              caption: "",
              field: "context_menu",
              type: "slot",
              slot: "context_menu",
              width: "50px",
            },
          ],
          types: {
            slot: {
              base_type: "SLOT",
            },
            users: {
              base_type: "DICT",
              data_source: await UserHelper.getTableUsersDict(),
            },
            areport_state: {
              base_type: "DICT",
              data_source: STATUS_AREPORT_NAME,
            },
            date_time: {
              base_type: "DATE",
              display: {
                date_format: "DD.MM.YYYY HH:MM",
              },
            },
            date: {
              base_type: "DATE",
            },
          },
          rows: this.list.result,
          edit: {},
          uniqId,
          tableName: "default",
          tableDataSetOptions: {
            display: {
              rowIndexCol: false,
            },
            filtersSet,
          },
          enableAutoSaveSettings: true,
        });
        const table = this.getTable();
        if (refresh === "record" && table) {
          const tableRows: TableRowObj[] = factory.getTableRows(
            table.tableDataSet.modelUnref,
          );
          table.tableDataSet.setTableRows(tableRows);
          return;
        }

        const tableDataSet = await factory.create();
        this.table = {
          factory,
          tableDataSet: tableDataSet,
        };
      } catch (e: any) {
        NotificationsService.send({
          type: "error",
          title: "Произошла ошибка при загрузке списка аналитических отчётов",
          text: await ApiHelper.getErrorMessage(e),
        });
        Logger.error({ e });
        this.error = true;
      }
    },

    initFilter(takeAwayFromUrl: boolean) {
      if (!takeAwayFromUrl) {
        this.filterData = this.filterDataActual;
        return;
      }

      const initFilter = this.$route.query.filter as string | undefined;
      if (initFilter) {
        try {
          this.filterDataActual = JSON.parse(initFilter);
          this.filterData = this.filterDataActual;
          this.open.filter = true;
        } catch (e: any) {
          return;
        }
      }
    },

    setFilterDataInUrl() {
      let query: any = Object.assign({}, this.$route.query);
      query.filter = JSON.stringify(this.filterData);
      this.$router.replace({ query });
    },

    getOptionsExport(aReportListValueDto: AReportDataDto): ContextMenuOption[] {
      return [
        {
          text: "Экспорт в .xlsx",
          click: () =>
            ApiAReportsService.exportToXlsx(aReportListValueDto.areport_id),
          icon: TABLE_ICONS.download,
        },
      ];
    },

    remove(aReportListValueDto: AReportDataDto) {
      ApiHelper.wrapNotifyError(
        ApiAReportsService.removeAReport(aReportListValueDto.areport_id),
        {
          resolve: () => {
            this.initList();
            this.initFilter(false);
          },
        },
      );
    },

    openContextMenu(event: PointerEvent, aReportDataDto: AReportDataDto) {
      ContextMenuService.send({
        event: event as any,
        options: [
          ...this.getOptionsExport(aReportDataDto),
          {
            text: "Удалить",
            click: () => {
              this.remove(aReportDataDto);
            },
            icon: TABLE_ICONS.trashCanOutline,
          },
        ],
      });
    },

    onSwitchRow(tableDataSet: TableDataSet, row: number) {
      const tableRow = tableDataSet.getRowOrUndefined(row);
      if (!tableRow) {
        return;
      }

      tableRow.select = !tableRow.select;
    },

    onClickLeftColumn(
      tableDataSet: TableDataSet,
      value: { event: PointerEvent; cell: TableHeaderCell },
    ) {
      if (value.cell.leftColumn?.key === "checkbox") {
        this.onRowCheck(tableDataSet);
        return;
      }
    },

    onRowCheck(tableDataSet: TableDataSet, row_no?: number) {
      if (row_no !== undefined) {
        const rowObj = tableDataSet.getRowOrUndefined(row_no);
        if (rowObj) {
          rowObj.select = !rowObj.select;
        }
      } else {
        const newValue = !tableDataSet.isSelectedAll;
        tableDataSet.visibleRows.forEach((rowObj) => {
          rowObj.select = newValue;
        });
      }
    },

    async onActionExec(tableDataSet: TableDataSet, action: ActionButtonDto) {
      try {
        tableDataSet.updateGlobalContext();
        const { selected_cell, page_data } =
          GlobalContext.get() as PageGlobalContext;
        const selected_rows = tableDataSet.getSelectedRowsData(true);
        const selected_row = tableDataSet.getSelectedRow()?.original;
        const result = await ApiActionsService.execute(action, {
          selected_rows,
          selected_row,
          selected_cell,
          page_data,
        });
        if (result.result?.refresh) {
          await this.initList(result.result.refresh);
        }
      } catch (ex) {
        Logger.error(ex);
        await ApiHelper.wrapNotifyError(ex, { isError: true });
      }
    },
  },
});
