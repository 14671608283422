import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3cc66130"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "reg-reports-filter" }
const _hoisted_2 = { class: "reg-reports-filter-row-1" }
const _hoisted_3 = { class: "reg-reports-filter-inputs" }
const _hoisted_4 = { class: "reg-reports-filter-buttons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TSelectComponent = _resolveComponent("TSelectComponent")!
  const _component_ButtonComponent = _resolveComponent("ButtonComponent")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", null, [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_TSelectComponent, {
            modelValue: _ctx.filterDataNew.areports_type,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.filterDataNew.areports_type) = $event)),
            "autoselect-max-count": 1,
            multiselect: true,
            autoselect: "",
            caption: "Вид аналитического отчёта",
            "options-url": "/api/repository/areports/types?action=list",
            onReset: _cache[1] || (_cache[1] = ($event: any) => (_ctx.filterDataNew.areports_type = undefined))
          }, null, 8, ["modelValue"])
        ]),
        _createElementVNode("div", null, "Найдено: " + _toDisplayString(_ctx.currentCount), 1)
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_ButtonComponent, {
          icon: _ctx.TABLE_ICONS.closeBlue,
          class: "button-blue",
          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('close')))
        }, {
          default: _withCtx(() => [
            _createTextVNode(" Скрыть ")
          ]),
          _: 1
        }, 8, ["icon"]),
        _createVNode(_component_ButtonComponent, {
          icon: _ctx.TABLE_ICONS.restoreBlue,
          class: "button-blue",
          onClick: _ctx.onReset
        }, {
          default: _withCtx(() => [
            _createTextVNode(" Очистить ")
          ]),
          _: 1
        }, 8, ["icon", "onClick"]),
        _createVNode(_component_ButtonComponent, {
          class: "form-button",
          "data-type": "accent",
          onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('on-filter')))
        }, {
          default: _withCtx(() => [
            _createTextVNode(" Отфильтровать ")
          ]),
          _: 1
        })
      ])
    ])
  ]))
}