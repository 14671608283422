export type StatusAReport =
  | "wait"
  | "temp"
  | "published"
  | "saved"
  | "archived";

export const STATUS_AREPORT_NAME: {
  [key in StatusAReport]: string;
} = {
  wait: "Формируется",
  temp: "Временный",
  published: "Опубликованный",
  saved: "Сохранен",
  archived: "Архивирован",
} as const;

export function getStatusAReportName(status: StatusAReport | undefined) {
  return STATUS_AREPORT_NAME[status!] || status || "Не указано";
}

export interface AReportSaveData {
  areport_name: string;
}
